<template>
    <section class="video">
        <div class="position-relative container py-5 px-0 px-xl-5">
            <div class="position-absolute pattern">
                <svg id="pattern-2"
                     class="h-100"
                     v-bind:class="{ 'done': isPatternVivusDone }"
                     version="1.2" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 93.6844406 272.4700623" xml:space="preserve">
                    <path fill="transparent" stroke="#B7C4D5" stroke-miterlimit="10" d="M45.4290199,56.1782074
                        c1.9350815,2.4771538,3.2537346,5.4393959,4.0010567,8.5437469c0.3390388,1.567749,0.5678406,3.1415482,0.530098,4.8030548
                        c-0.0263176,0.8295975-0.1584511,1.6850281-0.4663696,2.5097198c-0.3196945,0.819931-0.7971039,1.5868225-1.4029922,2.2217865
                        c-1.2139549,1.2828598-2.8074608,2.0590668-4.4196243,2.4948196c-1.6246071,0.4521027-3.3121376,0.5331116-4.920475,0.4859619
                        c-3.2401047-0.120697-6.323101-0.8071442-9.3234081-0.8717194c-2.9459114-0.0984726-6.0290108,0.4740601-8.1615715,2.2769699
                        c-1.0520859,0.8928528-1.8198471,2.0900726-2.2546158,3.4626541c-0.4334488,1.3725891-0.6115112,2.8682785-0.670845,4.3899918
                        c-0.059639,1.5219116,0.0085297,3.085022,0.0169525,4.6530228l0.0399094,4.6969147
                        c-0.029335,3.133728,0.0100632,6.2644424-0.079834,9.4008179c-0.6340332-6.2562332-1.2573395-12.4321671-1.5375977-18.7701721
                        c-0.0226974-1.6020508,0.0844193-3.2563248,0.5563889-4.8852844c0.4566574-1.6261063,1.3758698-3.2034531,2.6840134-4.374382
                        c1.3101883-1.1719284,2.9217377-1.8870621,4.5364304-2.319664c1.6263428-0.4161758,3.2855415-0.5726547,4.9258766-0.5289154
                        c3.2653217,0.1213226,6.3359318,0.8166733,9.3538761,0.9833679c1.5121956,0.0879745,2.9888496,0.0558319,4.3987694-0.2920837
                        c1.4028816-0.3434143,2.7511253-0.9417419,3.7490616-1.8942642c2.1057739-1.8798218,1.8639488-5.2946701,1.4879303-8.2631607
                        C48.0681839,61.8541794,46.9842758,58.8980827,45.4290199,56.1782074z"></path>
                    <path fill="transparent" stroke="#B7C4D5" stroke-miterlimit="10" d="M27.5140171,93.2713318
                        c-2.9128494,4.8416367-5.9714508,9.7100067-10.2066193,13.6331482c-2.0970993,1.940712-4.3899078,3.7095261-6.9527435,5.0880814
                        c-2.5479202,1.3647842-5.4736862,2.3108444-8.5059814,2.1214294l0.7878876-0.6164627
                        c-0.6357269,4.108902-0.0955048,8.4147568,1.6793442,12.1164093c0.8909454,1.8441238,2.1084671,3.5218811,3.6190338,4.8640289
                        c1.5088348,1.3435669,3.3091507,2.3283997,5.2399979,2.9068909l0.8965149,0.2686005l-0.2398376,0.9029236
                        c-1.8159866,6.8367462-1.4795074,14.0730743,0.1191101,20.9912262c1.6072922,6.9425201,4.2815781,13.6411896,6.9939957,20.3231659
                        c2.6476746,6.6703186,6.0229645,13.0321198,9.7577858,19.1898041c0.9412994,1.5260773,1.8962116,3.0823975,2.8749218,4.5572968
                        c1.0201187,1.4529877,2.1777992,2.8459625,3.3302193,4.2285614c2.3271713,2.7553253,4.7315559,5.4532471,7.1407852,8.1474304
                        c-2.5982475-2.5139008-5.1379547-5.0871277-7.6000023-7.7419891c-1.2270317-1.3338623-2.450695-2.6641083-3.5807648-4.1153259
                        c-1.1008968-1.4787445-2.0927944-2.9679108-3.0984173-4.4842529c-3.9950066-6.0442047-7.5756264-12.4137115-10.407383-19.1223907
                        c-2.8229294-6.6473389-5.6526489-13.3849792-7.3198853-20.5415955c-0.8290863-3.5690155-1.3846588-7.2350769-1.4197693-10.9348755
                        c-0.0494537-3.695755,0.3901596-7.4109039,1.3431015-10.9903564l0.6565323,1.1712494
                        c-2.1951828-0.6591492-4.2539291-1.8159485-5.9349823-3.3716278c-1.6894608-1.5480042-3.0159914-3.4471436-3.9654849-5.4918442
                        c-1.9064636-4.1037674-2.3422394-8.7518845-1.5513229-13.1239471l0.1221085-0.6750107l0.666008,0.0583649
                        c2.6572344,0.2328491,5.3642044-0.5337524,7.8345184-1.7373428c2.485199-1.2127838,4.7579498-2.8776398,6.912941-4.6714249
                        c2.1471939-1.7924423,4.0527573-3.8791962,5.8054886-6.0903625C24.2586231,97.9140015,25.8849888,95.5909729,27.5140171,93.2713318z"></path>
                    <path fill="transparent" stroke="#B7C4D5" d="M29.8603878,194.3694916
                        c-0.2239799,4.8591461-0.4552803,9.6894684-0.603714,14.5207672c-0.1348915,4.830719-0.2330818,9.6575012-0.2099342,14.4849396
                        c-0.011837,4.8274536,0.0951691,9.6539917,0.2394485,14.4843445c0.1578445,4.8309326,0.3969231,9.6607666,0.627327,14.5198212
                        c-1.0560226-4.7501831-1.7329102-9.5776062-2.1849442-14.4218445c-0.4638672-4.8452454-0.6575546-9.7132874-0.6806335-14.5804901
                        c0.0166245-4.8671875,0.2013779-9.7355499,0.6569061-14.5815125
                        C28.1485653,203.9505463,28.8155384,199.121933,29.8603878,194.3694916z"></path>
                    <path fill="transparent" stroke="#B7C4D5" d="M53.2528305,198.8098907
                        c-4.1796379,4.8729095-6.7817841,10.7133026-9.0835991,16.6024475l-6.6744919,17.9053955
                        c-2.2307091,5.963562-4.3569336,11.955368-6.3134899,18.003479c-1.9211845,6.0587006-3.6660423,12.1753998-5.0809135,18.4102325
                        c0.3010483-3.1839905,0.8322754-6.3411865,1.3944092-9.4958191c0.6303978-3.1393433,1.3520508-6.2633667,2.1895523-9.355896
                        c0.8259964-3.0956268,1.7819633-6.1535034,2.7599087-9.2015839c1.0106239-3.0369263,2.0791702-6.0528717,3.1764183-9.0553284
                        c2.2677269-5.9851685,4.5623703-11.9166107,7.1122894-17.8055878c1.288784-2.9378204,2.7280388-5.8305664,4.4305077-8.5684967
                        C48.903347,203.5395966,50.8730965,200.9547729,53.2528305,198.8098907z"></path>
                    <path fill="transparent" stroke="#B7C4D5" d="M19.4900951,0.0000006
                        c-1.1312866,1.2870464-1.9043274,2.8642597-2.2740479,4.5224285c-0.3751526,1.6600876-0.3704529,3.3884239-0.0887756,5.0568051
                        c0.5651245,3.3406448,2.1691895,6.4921408,4.4994507,8.9175901c2.3827209,2.399025,5.326355,4.1802711,8.4264832,5.5335388
                        c3.10742,1.3630028,6.3735332,2.3479729,9.6525555,3.3084755c3.2792358,0.9658279,6.5849915,1.9575729,9.7738342,3.3153572
                        c3.1786499,1.3538303,6.2728577,3.0972538,8.8326111,5.5365467c2.5808411,2.4210892,4.4359436,5.5560684,5.5952454,8.8584824
                        c1.1716652,3.3132858,1.7273598,6.7916946,1.9466286,10.2536545c0.1885986,3.4639435,0.1264648,6.9308395-0.1834717,10.3768005
                        c-0.3195496,3.4442673-0.8734741,6.8579483-1.5663147,10.2343063c-1.3912392,6.7558517-3.3590736,13.35112-5.3775063,19.8930283
                        c-2.0170593,6.5437622-4.0860596,13.054245-5.7187195,19.6580505c-0.8114319,3.3015747-1.5097046,6.6257172-2.0292969,9.9723969
                        c-0.5205688,3.3503189-0.8853455,6.7051773-0.8674011,10.0545578c0.017334,3.3421326,0.4037476,6.6868134,1.4168396,9.8205414
                        c0.9998474,3.1317444,2.6956787,6.0146179,5.0905151,8.2247925c2.3919678,2.2394562,5.3652649,3.8952026,8.2620277,5.7854309
                        c1.4731445,0.9501801,2.838562,1.9868622,4.2363281,2.9522095l4.1986694,2.9689941
                        c2.788208,2.0091705,5.5479736,4.092041,8.1242371,6.4306488c2.5791321,2.3200989,4.9424133,5.0175323,6.6825867,8.0781403
                        c1.7860413,3.0369415,2.9977112,6.3599548,3.8232422,9.7371063c0.8331604,3.380722,1.2941284,6.8259735,1.5427551,10.2711182
                        c0.0979309,1.7245026,0.1841431,3.4483185,0.1784973,5.1722717c0.0690918,1.7079926-0.0761108,3.5017853-0.2895813,5.2190552
                        c-0.5046387,3.4494171-1.4337463,6.7905884-2.531189,10.0558014c-2.2307129,6.5231171-5.1238708,12.7737122-8.3408508,18.8313904
                        c-3.2395325,6.0479126-6.7839966,11.925293-10.622467,17.6026611c-1.9241333,2.8349915-3.9185181,5.6237793-6.0400085,8.3147583
                        c-1.0652161,1.3416443-2.155674,2.6641235-3.3076515,3.9324341c-1.1471863,1.2696533-2.3363647,2.5129089-3.676178,3.5806885
                        c1.3095703-1.1037598,2.4609375-2.3757629,3.5705566-3.6735535c1.1138916-1.29599,2.1651649-2.6446228,3.1904945-4.0112915
                        c2.0479126-2.7360229,3.9891357-5.5508118,5.8469543-8.4186401c3.7113953-5.7384644,7.124115-11.6687317,10.2238464-17.750946
                        c3.0681152-6.092804,5.9043884-12.3214874,8.0101318-18.7863007c1.041687-3.2297363,1.9091797-6.5221252,2.3539734-9.854599
                        c0.1820374-1.6790771,0.303894-3.3071899,0.2122192-5.021347c-0.0174255-1.6991425-0.125885-3.3914337-0.244751-5.0821838
                        c-0.5810547-6.7202911-1.9920044-13.4932709-5.374939-19.2561646c-1.6632385-2.8895721-3.8751526-5.3978729-6.3862-7.6400452
                        c-2.5028687-2.2519531-5.2233276-4.2890472-7.9943237-6.2662506l-4.1960144-2.9386597
                        c-1.4216919-0.9730988-2.7893677-2.0005188-4.1975746-2.8980408c-2.8269043-1.8349457-5.9063416-3.5103455-8.5456848-5.9482727
                        c-2.6598206-2.429306-4.554657-5.6343536-5.6443176-9.0080261c-1.109375-3.3831329-1.5243835-6.9192657-1.5593872-10.4103851
                        c-0.0491638-3.5007019,0.3755798-6.9679565,0.9122009-10.374382c0.5457764-3.413681,1.2714844-6.7898254,2.1082458-10.1296692
                        c1.6836243-6.6791992,3.7936401-13.2120667,5.8365173-19.7366257c2.0451355-6.5227585,4.0236816-13.0545425,5.4238586-19.7038803
                        c1.4041443-6.639389,2.1883583-13.4175949,1.8975258-20.1710205c-0.1665039-3.3621292-0.6609535-6.7157364-1.7313576-9.8768692
                        c-1.0631104-3.1558533-2.7536316-6.098053-5.1444092-8.404892c-2.375061-2.3276634-5.315155-4.0499802-8.3991394-5.4122829
                        c-3.0933838-1.368824-6.344635-2.3912544-9.6115723-3.4069061c-3.2620239-1.0351906-6.544342-2.1034393-9.6780682-3.5616722
                        c-3.1275635-1.4494438-6.1045837-3.3378334-8.5006104-5.8614197c-2.3520508-2.5624657-3.9141235-5.8193884-4.4129639-9.2402601
                        c-0.2365112-1.709641-0.1877136-3.4683766,0.2427368-5.1390462C17.4918041,2.8172708,18.3209972,1.2522093,19.4900951,0.0000006z"></path>
                    <path fill="transparent" stroke="#B7C4D5" d="M31.9730358,123.869072
                        c1.0792027,1.5465012,2.223299,2.9245148,3.4997883,4.1491623c1.2760162,1.2216492,2.6773491,2.2749329,4.1685524,3.1759491
                        c1.4955025,0.8933716,3.0808067,1.6410065,4.7575226,2.196228c1.6792946,0.5548553,3.4316216,0.9226532,5.3035393,1.1557465
                        c-1.7963333,0.5996704-3.7692261,0.6730194-5.6700325,0.3612976c-1.9053841-0.3105927-3.7617531-0.9936829-5.4225082-2.0004883
                        c-1.6645241-1.0000458-3.1374702-2.3196564-4.3020897-3.8592224
                        C33.1439285,127.5132828,32.2854729,125.7363358,31.9730358,123.869072z"></path>
                    <path fill="transparent" stroke="#B7C4D5" d="M22.8207836,19.4688168
                        c1.0107422,2.1969185,1.4556885,4.698082,1.2810364,7.1730652c-0.0610962,1.2416878-0.3095703,2.4707756-0.6657104,3.6670532
                        c-0.339447,1.2040176-0.8555298,2.3571911-1.4814453,3.4462452c-0.611908,1.0981865-1.3754272,2.116478-2.2599182,3.0177841
                        l-0.5957031,0.5965576c-0.1564331,0.1617432-0.2094421,0.2984924-0.2075806,0.2811584
                        c-0.0040894-0.0215607-0.0694885-0.0209045,0.0653076,0.1233139c0.1106262,0.1211243,0.3717651,0.2790833,0.6137695,0.4328995
                        c0.9766846,0.6032104,2.0239563,1.1298676,3.0953064,1.572525c1.0706177,0.4303436,2.1906433,0.8106689,3.3044128,0.8692245
                        l0.37323,0.0196228l0.0134888,0.3463516c0.0436401,1.119278-0.3841553,2.2756805-1.0646362,3.1091385
                        c-0.3198547,0.4183426-0.6846619,0.8250961-0.7830505,1.2026062c-0.0926208,0.3933487,0.0765381,0.6751404,0.5327454,0.8327332
                        c0.8821716,0.3217773,1.9685059,0.3894577,3.001709,0.5968399c1.0457764,0.1968918,2.1032715,0.4982147,3.1077881,0.9857407
                        l1.6806316,0.8156509l-1.8963604,0.3228302l-2.3495789,0.3999939c-0.372467,0.0668335-0.7581482,0.1389465-1.035614,0.2372894
                        c-0.0633545,0.0230331-0.1257324,0.0505753-0.138916,0.0636063c-0.0204773,0.0001678,0.0405579,0.0381546,0.1765747-0.2119675
                        c0.0571899-0.1202621,0.0592957-0.3137131,0.0327454-0.3933334c-0.0244446-0.0960083-0.0578308-0.1317673-0.0560303-0.1405029
                        c-0.0219116-0.0287018,0.0230408,0.0144806,0.0654907,0.0429535c0.1086426,0.0861588,0.1939392,0.0979233,0.5162659,0.3476715
                        c0.226593,0.187851,0.3723755,0.3529816,0.525177,0.5238724c0.2932739,0.3378754,0.5461121,0.6735992,0.7842102,1.0218506
                        c0.2377625,0.3501663,0.4603271,0.6970596,0.6768799,1.0948257c0.2426758,0.4028854,0.4194946,1.0531006,0.4190063,1.5466537
                        c0.0180359,1.0346298-0.2588196,1.8474731-0.3614197,2.5407104c-0.055481,0.3420868-0.0722656,0.6452103-0.0348511,0.8726807
                        c0.0366821,0.2323608,0.10849,0.356926,0.2778015,0.6159821c0.6043091,0.8877106,1.9639568,1.270401,3.3709393,1.316658
                        c1.4002991,0.0559006,2.9278259-0.2687607,4.413208-0.5862732c1.4811401-0.3574219,2.9445496-0.8285065,4.35672-1.4384689
                        c2.8395081-1.1939774,5.4801636-2.9025421,7.8561096-5.000412l0.1473999,0.1351929
                        c-1.9196777,2.564949-4.4695129,4.6786957-7.3267212,6.2599182c-1.4342957,0.7838669-2.9571838,1.4162292-4.5334778,1.888588
                        c-0.7870483,0.2386703-1.585907,0.4507599-2.3995056,0.5900269c-0.8007813,0.1641083-1.6852722,0.2677155-2.5537415,0.2648315
                        c-0.8774414-0.0060577-1.7799358-0.1131821-2.6780682-0.4466629c-0.8901367-0.3134308-1.7965393-0.9079819-2.3919067-1.7815781
                        c-0.2679443-0.3998566-0.5463867-1.0064087-0.5882874-1.5686951c-0.0577698-0.5603867,0.0162659-1.0545654,0.1029663-1.4898529
                        c0.1851807-0.859993,0.4236755-1.5757446,0.4265137-2.1354599c-0.0064392-0.2976151-0.0198669-0.4417191-0.1646118-0.7322998
                        c-0.149353-0.3050079-0.3399048-0.635376-0.5348206-0.9486618c-0.1981812-0.3128281-0.4041443-0.6244125-0.614624-0.8981857
                        c-0.1018982-0.1317749-0.2163391-0.2677917-0.2915649-0.337265c0.0211792-0.008461-0.2539978-0.1736374-0.451355-0.3399658
                        c-0.1098328-0.0969238-0.2189636-0.1822968-0.3566284-0.3724747c-0.0599976-0.0847931-0.1423645-0.2110291-0.1932983-0.4009552
                        c-0.0549622-0.1721878-0.0572815-0.4736023,0.0454102-0.682251c0.2165222-0.4345474,0.4587402-0.5080261,0.6100769-0.5983505
                        c0.1634827-0.0771637,0.2903442-0.1114197,0.4162292-0.1458054c0.4855957-0.1175156,0.8885803-0.1496048,1.3008728-0.184906
                        l2.3731384-0.138855l-0.2157288,1.1383667c-0.8175354-0.5227356-1.7503967-0.8900528-2.7263489-1.1723785
                        c-0.4880981-0.1435547-0.9877625-0.266037-1.4957275-0.3948059c-0.5093384-0.127327-1.0284729-0.2307434-1.5610962-0.4697113
                        c-0.2608337-0.1207504-0.5484009-0.292038-0.7483215-0.6044235c-0.1864624-0.3274384-0.2058411-0.6766663-0.1175537-1.0009384
                        c0.2013855-0.6344604,0.6142883-0.9852829,0.8901672-1.4080582c0.2960205-0.4067993,0.5372009-0.8267517,0.6746826-1.2830124
                        c0.1308594-0.4578018,0.1520081-0.9392853,0.1094055-1.4182968l0.3865356,0.3657684
                        c-1.2808533,0.0251007-2.4960632-0.242897-3.6741028-0.5878143c-1.1787109-0.3517914-2.3209229-0.7954865-3.4362488-1.3412399
                        c-0.2728882-0.1455078-0.5510254-0.254425-0.9020081-0.5145187c-0.1676941-0.1279678-0.3504028-0.2983322-0.4985657-0.5438004
                        c-0.1500549-0.2452469-0.2398376-0.5617447-0.2244263-0.862587c0.0339355-0.6228638,0.3285217-1.0155411,0.5505066-1.3050232
                        c0.2488403-0.3010178,0.451416-0.501297,0.6242676-0.7128983c0.776886-0.813221,1.4338684-1.7352371,2.0282898-2.6935387
                        c1.1838379-1.9263859,1.9546814-4.1083279,2.3465271-6.369566c0.380249-2.2672272,0.3409424-4.6224518-0.1533203-6.9586029
                        L22.8207836,19.4688168z"></path>
                </svg>
            </div>
            <div class="video-wrapper d-flex justify-content-center p-1 p-xl-5">
                <b-embed type="iframe"
                         aspect="16by9"
                         src="https://www.youtube.com/embed/Pc4ErdcDUss"
                         allowfullscreen>
                </b-embed>
            </div>
        </div>
    </section>
</template>

<script>
    import {
        BEmbed,
    } from "bootstrap-vue";

    import Vivus from "vivus";

    export default {
        name: "MonoSculptingVideo",
        components: {
            BEmbed,
        },
        data () {
            return {
                patternVivus: null,
                isPatternVivusDone: false,
            };
        },
        mounted () {
            this.patternVivus = new Vivus(
                "pattern-2",
                {
                    type: "delayed",
                    start: "inViewport",
                    duration: 150
                },
                () => {
                    this.isPatternVivusDone = true;
                }
            );
        }
    }
</script>

<style lang="scss" scoped>
    @import "./variables.scss";

    .video {
        background-color: $background-color-alt;

        .pattern {
            top: 1rem;
            left: 0;
            bottom: 1rem;

            svg {
                &#pattern-2 {
                    path {
                        transition: fill 250ms;
                    }

                    &.done {
                        path {
                            fill: $background-color;
                        }
                    }
                }
            }
        }
    }
</style>
